import * as React from "react";
import Layout from "../components/Layout";
import Head from "../components/Head";
import LevercodeStack from "../images/levercode-stack.png";

const ContactPage = () => (
  <Layout>
    <Head title="Expertise" />
    {/* Content */}

    <div className="container max-w-screen-xl pt-16 sm:pt-32 pb-16 sm:pb-64 sm:text-2xl">
      <h1 className="_h1">Expertise</h1>
      <p className="_p">
        Levercode brings together comprehensive expertise and deep knowledge in
        a wide array of technologies. In our everyday work, we rely mostly on
        our{" "}
        <strong>
          well trained and experienced in-house technology experts
        </strong>
        . Our experts have worked for various Estonian and international
        technology companies, other industries and Government institutions. As a
        result, we are prepared to put ourselves into clients' shoes and
        understand their specific needs and issues.
      </p>

      {/* <p className="_p">
        In addition, Levercode has signed a <strong>Partnership Agreement with Tallinn Technical University (Taltech)</strong>, one of the leading educational and research institutions on e-Governance and Cybersecurity in the world.{" "}
        <strong>A number of world-renowned data scientists and cryptographers</strong> that have stood behind Estonian success in digitalization are participants in the cooperation. They are involved in activities of Levercode through its Research Lab
        (Levercode Lab & Research Ltd). Such connection keeps Levercode’s intellectual capacity always on the forefront and provides the company with a constant supply of top-notch technologies applied in e-Governance suites. Everything we do is
        scientifically proved and tested!{" "}
      </p> */}
      <p className="_p">
        Levercode’s horizontal{" "}
        <strong>
          expertise is multifunctional and can be utilized both in the public
          and private sector
        </strong>
        . While the primary focus of Levercode is on designing reliable
        e-Governance ecosystems for Governments, a number of its components or
        sub-systems are utilized also by private vertical industries such as
        finance and telecommunications. Estonian experience shows that
        commercial banks and telecommunication firms were the first to integrate{" "}
        <strong>Digital Identity and Signature</strong> into their client
        authentication and service provision platforms. Similarly, these
        sub-systems play important roles in other industries and public service
        domains like Digital Health, for example. Levercode has developed{" "}
        <strong>
          Post-Quantum Capable Digital Identity and Signature Platform Lever ID
        </strong>{" "}
        (see:{" "}
        <a
          className="text-blue-800"
          href="https://leverid.com"
          target="_blank"
          rel="noreferrer"
        >
          leverid.com
        </a>{" "}
        ) that is available both for Governments and companies.
      </p>
      <p className="_p">
        Besides Digital Signature capable Identity platform,{" "}
        <strong>Registries and Interoperability</strong> (in the form of Data
        Exchange Layer) are major indispensable building blocks that form the
        Foundation - prerequisite of sustainable and reliable e-Government​. All
        three are separate, but at the same time closely related​ and form the
        platforms that allow Governments and companies to build e-services on
        top of the Foundation.
      </p>
      <p className="_p">
        <strong>Levercode Stack</strong> (see image below) gives a good overview
        of the interconnection of the components of foundational layer (ID, Data
        Exchange Layer, and Registries), Compliance and Proof Tech tools in
        which Levercode has acquired profound expertise.
      </p>
      <p className="_p">
        <img src={LevercodeStack} alt="Levercode Stack" />
      </p>
      <p className="_p">
        Levercode’s solid expertise in the utilisation of{" "}
        <strong>Blockchain and other Distributed Ledger Technologies</strong>{" "}
        (DLT) allows us to build systems and applications for Fintech and other
        sectors in which getting the proof of an event is of crucial importance.
        Producing such proof is essential for systems using Wallets and/or
        Messaging Systems as their underlying mode of operation.{" "}
      </p>
      <p className="_p">
        <strong>Modularity and future-proofing</strong> are important concepts
        and principles for Levercode. Our expertise entails designing and
        creating the data systems that, whilst relying largely on existing open
        source technologies, allow us to add new functional modules or
        technology upgrades without the need to replace or redesign the entire
        system. This way, the client gets a sustainable, cost-effective data
        system and can focus mostly on its core business.
      </p>
      <p className="_p">
        <strong>Compliance</strong> has become one of the dominant keywords or
        principles in our work. First of all, Levercode’s expertise lies in
        evaluating the operation of existing information systems against the
        existing legal environment. After identification of the gaps in
        compliance, we can either provide a Blueprint for modification of the
        system or suggest necessary changes in legislation, depending on the
        needs of the client or demands of the environment.{" "}
      </p>
      <p className="_p">
        Levercode has also expertise in designing and providing{" "}
        <strong>secure infrastructure and hosting</strong> to protect your data
        and business processes from the risks stemming from the outside world.
        We conduct extensive security testing according to the best practices of
        the industry and provide private servers that ensure that your products'
        uptime is maximized and your clients can continue to use your products
        and services with minimum downtime.{" "}
      </p>
    </div>
  </Layout>
);

export default ContactPage;
